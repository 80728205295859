import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/main-page/main-page.module').then(
        (m) => m.MainPageModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne',
    loadChildren: () =>
      import('./pages/offer/internal-doors/internal-doors.module').then(
        (m) => m.InternalDoorsModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-baranski',
    loadChildren: () =>
      import('./pages/offer/internal-doors/baranski/baranski.module').then(
        (m) => m.InternalBaranskiModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dallas',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dallas/dallas.module').then(
        (m) => m.InternalDallasModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/dre.module').then(
        (m) => m.InternalDreModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-auri',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/auri/auri.module').then(
        (m) => m.DreAuriModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-berge',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/berge/berge.module').then(
        (m) => m.DreBergeModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-binito',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/binito/binito.module').then(
        (m) => m.DreBinitoModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-nestor',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/nestor/nestor.module').then(
        (m) => m.DreNestorModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-nova',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/nova/nova.module').then(
        (m) => m.DreNovaModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-scala',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/scala/scala.module').then(
        (m) => m.DreScalaModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-polyskowe',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/shiny/shiny.module').then(
        (m) => m.DreShinyModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-standard',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/standard/standard.module').then(
        (m) => m.DreStandardModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-uni',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/uni/uni.module').then(
        (m) => m.DreUniModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-vetro-d',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/vetro-d/vetro-d.module').then(
        (m) => m.DreVetroDModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-vetro-e',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/vetro-e/vetro-e.module').then(
        (m) => m.DreVetroEModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-dre-wood',
    loadChildren: () =>
      import('./pages/offer/internal-doors/dre/wood/wood.module').then(
        (m) => m.DreWoodModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-francuskie',
    loadChildren: () =>
      import('./pages/offer/internal-doors/french/french.module').then(
        (m) => m.InternalFrenchModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-jagdor-jagras',
    loadChildren: () =>
      import(
        './pages/offer/internal-doors/jagdor-jagras/jagdor-jagras.module'
      ).then((m) => m.InternalJagdorJagrasModule),
  },
  {
    path: 'drzwi-wewnetrzne-stara-metalowa-oscieznica',
    loadChildren: () =>
      import('./pages/offer/internal-doors/old-frame/old-frame.module').then(
        (m) => m.InternalOldFrameModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-voster',
    loadChildren: () =>
      import('./pages/offer/internal-doors/voster/voster.module').then(
        (m) => m.InternalVosterModule
      ),
  },
  {
    path: 'drzwi-wewnetrzne-akcesoria',
    loadChildren: () =>
      import(
        './pages/offer/internal-doors/accessories/accessories.module'
      ).then((m) => m.AccessoriesModule),
  },
  {
    path: 'listwy-przypodlogowe',
    loadChildren: () =>
      import(
        './pages/offer/internal-doors/accessories/boards/boards.module'
      ).then((m) => m.AccessoriesBoardsModule),
  },
  {
    path: 'klamki-do-drzwi',
    loadChildren: () =>
      import(
        './pages/offer/internal-doors/accessories/handles/handles.module'
      ).then((m) => m.AccessoriesHandlesModule),
  },
  {
    path: 'drzwi-zewnetrzne',
    loadChildren: () =>
      import('./pages/offer/external-doors/external-doors.module').then(
        (m) => m.ExternalDoorsModule
      ),
  },
  {
    path: 'drzwi-zewnetrzne-baranski',
    loadChildren: () =>
      import('./pages/offer/external-doors/baranski/baranski.module').then(
        (m) => m.ExternalBaranskiModule
      ),
  },
  {
    path: 'drzwi-zewnetrzne-dallas',
    loadChildren: () =>
      import('./pages/offer/external-doors/dallas/dallas.module').then(
        (m) => m.ExternalDallasModule
      ),
  },
  {
    path: 'drzwi-zewnetrzne-kmt',
    loadChildren: () =>
      import('./pages/offer/external-doors/kmt/kmt.module').then(
        (m) => m.ExternalKMTModule
      ),
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania',
    loadChildren: () =>
      import(
        './pages/offer/apartment-internal-doors/apartment-internal-doors.module'
      ).then((m) => m.ApartmentInternalDoorsModule),
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania-baranski',
    loadChildren: () =>
      import(
        './pages/offer/apartment-internal-doors/baranski/baranski.module'
      ).then((m) => m.ApartmentInternalBaranskiModule),
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania-dallas',
    loadChildren: () =>
      import(
        './pages/offer/apartment-internal-doors/dallas/dallas.module'
      ).then((m) => m.ApartmentInternalDallasModule),
  },
  {
    path: 'drzwi-wejsciowe-do-mieszkania-przeciwpozarowe',
    loadChildren: () =>
      import(
        './pages/offer/apartment-internal-doors/fireproof/fireproof.module'
      ).then((m) => m.ApartmentInternalFireproofModule),
  },
  {
    path: 'drzwi-loftowe-drzwi-szklane',
    loadChildren: () =>
      import('./pages/offer/loft-doors/loft-doors.module').then(
        (m) => m.LoftDoorsModule
      ),
  },
  {
    path: 'drzwi-loftowe',
    loadChildren: () =>
      import('./pages/offer/loft-doors/loft/loft.module').then(
        (m) => m.LoftLoftModule
      ),
  },
  {
    path: 'drzwi-szklane',
    loadChildren: () =>
      import('./pages/offer/loft-doors/glass/glass.module').then(
        (m) => m.LoftGlassModule
      ),
  },
  {
    path: 'drzwi-szklane-do-wiatrolapu',
    loadChildren: () =>
      import('./pages/offer/loft-doors/vestibule/vestibule.module').then(
        (m) => m.LoftVestibuleModule
      ),
  },
  {
    path: 'scianki-loftowe-sciany-szklane',
    loadChildren: () =>
      import('./pages/offer/loft-doors/walls/walls.module').then(
        (m) => m.LoftWallsModule
      ),
  },
  {
    path: 'drzwi-ukryte',
    loadChildren: () =>
      import('./pages/offer/hidden-doors/hidden-doors.module').then(
        (m) => m.HiddenDoorsModule
      ),
  },
  {
    path: 'drzwi-przesuwne',
    loadChildren: () =>
      import('./pages/offer/sliding-doors/sliding-doors.module').then(
        (m) => m.SlidingDoorsModule
      ),
  },
  {
    path: 'drzwi-przesuwne-szklane',
    loadChildren: () =>
      import('./pages/offer/sliding-doors/glass/glass.module').then(
        (m) => m.SlidingGlassModule
      ),
  },
  {
    path: 'drzwi-przesuwne-drewniane',
    loadChildren: () =>
      import('./pages/offer/sliding-doors/wooden/wooden.module').then(
        (m) => m.SlidingWoodenModule
      ),
  },
  {
    path: 'drzwi-lamane-drzwi-skladane',
    loadChildren: () =>
      import('./pages/offer/sliding-doors/folding/folding.module').then(
        (m) => m.SlidingFoldingModule
      ),
  },
  {
    path: 'scianki-prysznicowe-kabiny-prysznicowe',
    loadChildren: () =>
      import('./pages/offer/showers/showers.module').then(
        (m) => m.ShowersModule
      ),
  },
  {
    path: 'drzwi-do-kamienicy-drzwi-zabytkowe',
    loadChildren: () =>
      import('./pages/offer/tenement-doors/tenement-doors.module').then(
        (m) => m.TenementDoorsModule
      ),
  },
  {
    path: 'realizacje',
    loadChildren: () =>
      import('./pages/realizations/realizations.module').then(
        (m) => m.RealizationsModule
      ),
  },
  {
    path: 'poradnik',
    loadChildren: () =>
      import('./pages/guide/guide.module').then((m) => m.GuideModule),
  },
  {
    path: 'o-nas',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'kontakt',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
